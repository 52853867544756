.nk-menu-link {
  color: black !important;
}
.nk-menu-link:hover {
  color: gray;
}
.nk-menu-link:focus-visible {
  color: red !important;
}
.nk-menu-icon {
  color: red !important;
}
.boxsh {
  box-shadow: 9px 4px 27px 3px #b1b0b05e;
}
.logo {
  position: relative !important;
  top: 80px !important;
  max-height: 150px !important;
}
.logo_login {
  position: relative !important;
  top: 50px !important;
  max-height: 200px !important;
}
.logo-img_login:not(:first-child) {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.logo-img-lg_login{
  max-height: 200px !important;
}
.logo-img:not(:first-child) {
  position: absolute;
  left: -110px;
  top: 50%;
  transform: translateY(-50%);
}

.nk-sidebar-head {
  display: flex;
  align-items: center;
  padding: 14px 24px;
  min-width: 100%;
  width: 290px;
  height: 100px;
}
.nk-menu-icon .icon {
  font-size: 24px;
  letter-spacing: normal;
  vertical-align: middle;
  color: #508e2e;
  transition: color 0.4s, background-color 0.4s;
}
.bg-theme{
  background-color: #508e2e !important;
}
.text-theme{
  color: #508e2e !important;
}
.btn-theme{
  background-color: #508e2e !important;
  color: #fff;
}
.cardsty {
  box-shadow: 2px 2px 5px lightgray;
  transition: 0.5s;
}
.cardsty:hover {
  box-shadow: 5px 5px 7px;
  /* transform: scale(1); */
  /* z-index: 999; */
}
.tattisty {
    -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  /* width: 200px;
  height: 200px; */
}
.welcome{
  font-size: 60pt;
  color: #fff;
  /* outline: #508e2e; */
  letter-spacing: 9px;
  font-family: fantasy;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow:   3px 3px 3px #0085e6,  
                 3px 3px 3px #0085e6,
                 3px 3px 3px #0085e6,                 
                 /* 0 19px 1px #0085e6, */
                 0 0 6px #508e2e,
                 0 6px 3px #508e2e,
                 0 12px 6px rgba(0,0,0,.2),
                 0 18px 18px rgba(0,0,0,.25),
                 0 24px 24px rgba(0,0,0,.2),
                 0 36px 36px rgba(0,0,0,.15);
}
.tattisty:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -webkit-transform: scale(1.08);
  transform: scale(1.06); 

  /* transform: scaleY(1.1); */
  /* animation: shake 0.15s;
  animation-iteration-count: infinite; */
}

.MuiIconButton-colorInherit {
  color: red;
}
.loader {
  background: url("../images/Droveloader.gif") 50% 50% no-repeat rgba(225, 225, 225, 0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 1;
}
.h-100vh {
  height: 100vh;
}
.bg-darkk {
  background-color: #000;
}
/* .image-admin {
  width: 100px;
} */
/* .font {
  font-family: fantasy;
  letter-spacing: 10px;
  text-shadow: 5px 5px 5px red;
} */
.borderr { 
  border: 5px solid red;
  border-radius: 10px;
}
.bg-red {
  background-color: red;
}
.bg-wlcome {
background-image: url('../images/bg-welcome.jpg');
background-repeat: no-repeat;
background-size: cover;
background-position: center;

}
.custom_card{
  border: 1px solid rgba(255, 255, 255, 0.5);
    text-shadow: 0 0 1em rgb(0 0 0 / 50%);
    background: rgba(255, 255, 255, 0.2);
}

.ml-6{
  margin-left: 4.75rem !important;
}
.card_icon{
  position: relative;
  left: 1.10em;
  font-size: 3em;
}
.logo-img-lg {
  max-height: 100px !important;
}
.fs-25{
  font-size: 25px !important;
}
.btn:hover, .dual-listbox .dual-listbox__button:hover {
  color: #fff;
  text-decoration: none;
}
.shadow{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.shadow:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
